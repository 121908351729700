import {library} from '@fortawesome/fontawesome-svg-core';

import {
  faAngleLeft,
  faAngleRight,
  faArrowsSpin,
  faBarsStaggered,
  faBuilding,
  faCheck,
  faCheckCircle,
  faCircleNotch,
  faCloudDownloadAlt,
  faCloudUploadAlt,
  faCode,
  faCopy,
  faEdit,
  faEnvelope,
  faEthernet,
  faExclamationCircle,
  faExclamationTriangle,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFileCircleQuestion,
  faFileInvoice,
  faFilter,
  faGlobe,
  faGlobeEurope,
  faHandPointRight,
  faHandshakeAngle,
  faHome,
  faInfoCircle,
  faKey,
  faLightbulb,
  faLink,
  faList,
  faMapMarkerAlt,
  faMoneyCheck,
  faNetworkWired,
  faPiggyBank,
  faPlugCircleBolt,
  faPlugCircleCheck,
  faPlugCircleXmark,
  faPlus,
  faQuestionCircle,
  faRedo,
  faRoute,
  faSave,
  faSearch,
  faServer,
  faShop,
  faShoppingCart,
  faSignature,
  faSignInAlt,
  faSignOutAlt,
  faSort,
  faSortDown,
  faSortUp,
  faStream,
  faTh,
  faThumbsUp,
  faTimes,
  faTimesCircle,
  faTrash,
  faTv,
  faUser,
  faUserCog,
  faUsers,
  faUserShield,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faAngleLeft,
  faAngleRight,
  faArrowsSpin,
  faBarsStaggered,
  faBuilding,
  faCheck,
  faCheckCircle,
  faCode,
  faCopy,
  faCircleNotch,
  faCloudDownloadAlt,
  faCloudUploadAlt,
  faEdit,
  faEnvelope,
  faEthernet,
  faExclamationCircle,
  faExclamationTriangle,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFileCircleQuestion,
  faFileInvoice,
  faFilter,
  faGlobe,
  faGlobeEurope,
  faHandPointRight,
  faHandshakeAngle,
  faHome,
  faInfoCircle,
  faKey,
  faLightbulb,
  faLink,
  faList,
  faMapMarkerAlt,
  faMoneyCheck,
  faNetworkWired,
  faPiggyBank,
  faPlus,
  faPlugCircleBolt,
  faPlugCircleCheck,
  faPlugCircleXmark,
  faQuestionCircle,
  faRedo,
  faRoute,
  faSave,
  faSearch,
  faServer,
  faSort,
  faSortDown,
  faSortUp,
  faStream,
  faShop,
  faShoppingCart,
  faSignature,
  faSignInAlt,
  faSignOutAlt,
  faTh,
  faTimes,
  faTimesCircle,
  faThumbsUp,
  faTrash,
  faTv,
  faUser,
  faUserCog,
  faUsers,
  faUserShield,
);
